import React from 'react';
import Layout from '../components/Layout';
import TournamentSlides from '../components/tournamentSlideshow';
import { Link } from 'gatsby';
import prospectus from './ChetwyndProspectus.pdf'


const Tournament = () => (
  <div>
    <Layout>
    <div className={"slideshow"}><TournamentSlides /></div>
      <div className={"content"}>
        <ul>
          <li>
            <h1>Steve Thomas International Senior Tournament</h1>
            <p>
              The Chetwynd Lawn Tennis Club hosts its 8th annual Steve Thomas 
              International Senior Tennis Tournament. This event will be held 
              from the 30 th May to 2nd June 2019 at the National Racquet 
              Centre, Tacarigua.
            </p>
            <h1><a href={prospectus} download>Click for Tournament Prospectus</a></h1>
            <div className={"tournament"}>
              <table>
                <tr>
                  <td><h3>Event</h3></td>
                  <td><h3>Winner</h3></td>
                  <td><h3>Finalist</h3></td>
                </tr>
                <tr>
                  <td>Over 35 Men’s Singles Open</td>
                  <td>$1000.00</td>
                  <td>Trophy</td>
                </tr>
                <tr> 
                  <td>Over 35 Men’s Singles B</td>
                  <td>$500.00</td>
                  <td>Trophy</td>
                </tr>
                <tr>
                  <td>Over 35 Men’s Singles C</td>
                  <td>$150.00</td>
                  <td>Trophy</td>
                </tr>
                <tr>
                  <td>Over 50 Men’s Singles</td>
                  <td>$500.00</td>
                  <td>Trophy</td>
                </tr>
                <tr>
                  <td>Over 60 Men’s Singles</td>
                  <td>$500.00</td>
                  <td>Trophy</td>
                </tr>
                <tr>
                  <td>Over 30 Ladies Singles Open</td>
                  <td>$1000.00</td>
                  <td>Trophy</td>
                </tr>
                <tr>
                  <td>Over 30 Ladies Singles C</td>
                  <td>$150.00</td>
                  <td>Trophy</td>
                </tr>
                <tr>
                  <td>Over 35 Men’s Doubles Open</td>
                  <td>$500.00</td>
                  <td>Trophy</td>
                </tr>
                <tr>
                  <td>Over 30 Ladies Doubles</td>
                  <td>$500.00</td>
                  <td>Trophy</td>
                </tr>
                <tr>
                  <td>Over 50 Men’s Doubles</td>
                  <td>$500.00</td>
                  <td>Trophy</td>
                </tr>
                <tr>
                  <td>Mixed Doubles (Ladies 30+, Men 35+)</td>
                  <td>$500.00</td>
                  <td>Trophy</td>
                </tr>
              </table>
            </div>
          </li>
        </ul>
      </div>
    </Layout>
  </div>
);

export default Tournament;
